import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["imageGrid", "template"]
  static values = {
    maxImages: Number,
    uploadPath: String,
    existingImages: Array,
    jwt: String,
    trainingExerciseId: String
  }

  connect() {
    this.trainingExerciseIdValue = (this.trainingExerciseIdValue === null || this.trainingExerciseIdValue === '') ? null : this.trainingExerciseIdValue
    this.loadExistingImages()
    this.updatePlaceholders()
  }

  loadExistingImages() {
    this.existingImagesValue.forEach(image => {
      const templateContent = this.templateTarget.innerHTML
      const template = document.createElement('div')
      template.innerHTML = templateContent

      const wrapper = template.firstElementChild

      wrapper.classList.remove('image-placeholder')
      wrapper.classList.add('image-container')

      const preview = wrapper.querySelector('.image-preview')
      const placeholder = wrapper.querySelector('.placeholder')
      const deleteButton = wrapper.querySelector('button')

      preview.style.backgroundImage = `url(${image.url})`
      preview.classList.remove('hidden')
      placeholder.classList.add('hidden')

      deleteButton.classList.remove('hidden')
      deleteButton.dataset.imageId = image.id

      const hiddenInput = document.createElement('input')
      hiddenInput.className = 'image-id-input'
      hiddenInput.type = 'hidden'
      hiddenInput.name = 'training_exercise[training_exercise_image_ids][]'
      hiddenInput.value = image.id
      wrapper.appendChild(hiddenInput)

      const fileInput = wrapper.querySelector('.upload-input')
      fileInput.remove()

      this.imageGridTarget.appendChild(wrapper)
    })
  }

  updatePlaceholders() {
    const currentImages = this.imageGridTarget.querySelectorAll('.image-container').length

    const needed = this.maxImagesValue - currentImages

    if (needed > 0) {
      for (let i = 0; i < needed; i++) {
        const templateContent = this.templateTarget.innerHTML
        const wrapper = document.createElement('div')
        wrapper.innerHTML = templateContent
        this.imageGridTarget.appendChild(wrapper.firstElementChild)
      }
    }
  }

  async upload(event) {
    const file = event.target.files[0]
    if (!file) return

    const wrapper = event.target.closest('.image-wrapper')
    const preview = wrapper.querySelector('.image-preview')
    const placeholder = wrapper.querySelector('.placeholder')
    const loading = wrapper.querySelector('.loading')
    const error = wrapper.querySelector('.error')
    const deleteButton = wrapper.querySelector('button')

    placeholder.classList.add('hidden')
    loading.classList.remove('hidden')

    const reader = new FileReader()
    reader.readAsDataURL(file)

    const formData = new FormData()
    formData.append('image', file)
    formData.append('jwt', this.jwtValue)

    try {
      const response = await fetch(this.uploadPathValue, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        }
      })

      if (response.ok) {
        const data = await response.json()

        preview.style.backgroundImage = `url(${data.url})`
        preview.classList.remove('hidden')
        loading.classList.add('hidden')

        deleteButton.dataset.imageId = data.id
        deleteButton.classList.remove('hidden')

        const hiddenInput = document.createElement('input')
        hiddenInput.type = 'hidden'
        hiddenInput.name = 'training_exercise[training_exercise_image_ids][]'
        hiddenInput.value = data.id
        wrapper.appendChild(hiddenInput)

        wrapper.classList.remove('image-placeholder')
        wrapper.classList.add('image-container')

        const fileInput = wrapper.querySelector('.upload-input')
        fileInput.remove()
      } else {
        loading.classList.add('hidden')
        error.classList.remove('hidden')

        // Revert preview if upload failed
        preview.style.backgroundImage = ''
        preview.classList.add('hidden')
        // placeholder.classList.remove('hidden')
      }
    } catch (error) {
      console.error('Upload failed:', error)

      loading.classList.add('hidden')
      error.classList.remove('hidden')

      preview.style.backgroundImage = ''
      preview.classList.add('hidden')
      // placeholder.classList.remove('hidden')
    }
  }

  async removeImage(event) {
    event.preventDefault()
    const button = event.currentTarget
    const wrapper = button.closest('.image-wrapper')
    const imageId = button.dataset.imageId

    if (imageId && this.trainingExerciseIdValue == null) {
      try {
        const response = await fetch(`${this.uploadPathValue}/${imageId}?jwt=${this.jwtValue}&training_exercise_id=${this.trainingExerciseIdValue}`, {
          method: 'DELETE',
          headers: {
            'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
          }
        })

        if (response.ok) {
          this.resetWrapper(wrapper)
        }
      } catch (error) {
        console.error('Delete failed:', error)
      }
    } else {
      this.resetWrapper(wrapper)
    }
  }

  resetWrapper(wrapper) {
    // Reset the preview
    const preview = wrapper.querySelector('.image-preview')
    preview.style.backgroundImage = ''
    preview.classList.add('hidden')

    // Show the placeholder
    const placeholder = wrapper.querySelector('.placeholder')
    placeholder.classList.remove('hidden')

    const deleteButton = wrapper.querySelector('button')
    deleteButton.classList.add('hidden')
    deleteButton.dataset.imageId = ''

    const imageLabel = wrapper.querySelector('.image-label')
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.classList.add("hidden");
    fileInput.classList.add("upload-input");
    fileInput.accept = "image/*";
    fileInput.setAttribute("data-action", "change->image-upload#upload");
    imageLabel.appendChild(fileInput);

    const hiddenInput = wrapper.querySelector('.image-id-input')
    if (hiddenInput) hiddenInput.remove()

    wrapper.classList.add('image-placeholder')
    wrapper.classList.remove('image-container')
  }
}